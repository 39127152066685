import { ReactElement, useCallback, useMemo } from 'react'
import { GetServerSideProps } from 'next'

import { Button } from 'ui/button'

import MainLayout from 'components/layout/main-layout'

import useTranslation from 'lib/hooks/useTranslation'
import useTracking from 'lib/hooks/useTracking'
import usePageTrackEvent from 'lib/hooks/usePageTrackEvent'

import { serverSideTranslations } from 'lib/utils/server'
import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'
import { SUPPORT_REQUEST_FORM } from 'lib/constants'

import HeaderBg from 'brand-assets/illustrations/helpcenter-header-bg.svg'

import SearchBar from './search-bar'
import Category from './category'
import Faq from './faq'

import s from './styles.module.scss'

const HelpCenter = () => {
  const { yellowAi } = useGlobalContext()
  const { t } = useTranslation('common')
  const { trackEvent } = useTracking({
    pageName: EVENTS.HELPCENTER,
  })
  usePageTrackEvent({ trackEvent })

  const onToggleYellowAi = useCallback(() => {
    yellowAi.showAndToggle()

    trackEvent?.({
      attributeId: EVENTS.HELPCENTER,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CHATBOT,
    })
  }, [trackEvent, yellowAi])

  const chatWithUsButton = useMemo(() => {
    return (
      <Button id="helpcenter-chat-with-us" size="large" onClick={onToggleYellowAi}>
        {t('action.chatWithUs')}
      </Button>
    )
  }, [onToggleYellowAi, t])

  return (
    <>
      <section className={s.header}>
        <HeaderBg className={s.headerBg} />
        <div className={s.searchHolder}>
          <h1 className={s.searchTitle}>{t('q.howCanWeHelpYouToday')}</h1>
          <div className={s.searchBarContainer}>
            <SearchBar />
            <span className={s.orText}>{t('t.or')}</span>
            {chatWithUsButton}
          </div>
        </div>
      </section>
      <section className={s.faq}>
        <h2 className={s.faqTitle}>{t('t.popularFaq')}</h2>
        <Faq />
      </section>
      <section className={s.category}>
        <h2 className={s.categoryTitle}>{t('t.browseByCategories')}</h2>
        <Category />
      </section>
      <section className={s.needHelp}>
        <h2 className={s.needHelpTitle}>{t('q.stillNeedHelp')}</h2>
        {chatWithUsButton}
        <span className={s.orText}>{t('t.or')}</span>
        <Button
          id="helpcenter-contact-us"
          variant="secondary"
          size="large"
          onClick={() => {
            if (window?.location) {
              window.location.href = SUPPORT_REQUEST_FORM
            }
          }}
        >
          {t('action.submitRequest')}
        </Button>
      </section>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  }
}

HelpCenter.getLayout = function getLayout(page: ReactElement) {
  return (
    <MainLayout
      hasSearch={false}
      variant="helpcenter"
      className={s.helpcenter}
      additionalParams={{ nonStickyHeader: true }}
    >
      <MainLayout.Content whiteBg className="l-main--no-pad-top">
        {page}
      </MainLayout.Content>
    </MainLayout>
  )
}

export default HelpCenter
