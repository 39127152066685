import React, { useState, useRef, ChangeEventHandler, KeyboardEventHandler } from 'react'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { HELP_CENTER_QUERY } from 'lib/constants'

import s from './styles.module.scss'

const SearchBar = () => {
  const { t } = useTranslation('common')
  const { isMobileView } = useGlobalContext()
  const [value, setValue] = useState<string>('')
  const placeholder = t('msg.searchForTopic')
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      window.location.href = HELP_CENTER_QUERY + e.currentTarget.value
    }
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value)
  }

  const onClear = () => {
    setValue('')
  }

  return (
    <div className={s.searchBar}>
      <Icon name="magnifying-glass" size="medium" />
      <input
        ref={(ref) => (inputRef.current = ref)}
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        placeholder={placeholder}
      />
      {value && (
        <Button
          iconName="delete-x"
          shape="circle"
          size={isMobileView ? 'small' : 'medium'}
          variant="tertiary-grey"
          className={s.clearButton}
          onClick={onClear}
        />
      )}
    </div>
  )
}

export default SearchBar
